import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import {Search, Clear } from '@mui/icons-material';
import {InputAdornment,TextField} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from 'react-router-dom';
import { useBoardRepository } from 'repository/RootProvider';
import CommonConst from 'constant/CommonConst';
import moment from 'moment';

const columns = [
  { field: 'boardNo', headerName: 'No', width: '100' },
  {
    field: 'title',
    headerName: '제목',
    // width: '60%',
    flex: 1,
    editable: true,
  },
  {
    field: 'createAt',
    headerName: '작성일자',
    width: '200',
    editable: true,
    valueFormatter: (value) => moment(value).format(CommonConst.DATE_TIME_FORMAT)
  }
];

const QnaBoard = observer(() => {
  const boardRepository = useBoardRepository();
  const navigate = useNavigate();
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [rows, setRows] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(""); 

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  useEffect(() => {
    doRefresh({ pageNo: 0, searchKeyword :  "" });
  }, []);


  const handleTextFieldChange = (event) => {
    const value = event.target.value;
    setShowClearIcon(value === "" ? "none" : "flex");
    setSearchKeyword(value);
  };

  const handleClear = () => {
    setSearchKeyword("");
    setShowClearIcon("none");
  };

  const doRefresh = async ({
    pageNo,
    searchKeyword = ""
  }) => {
    try {
      setLoading(true);
      let result = await boardRepository.getContentList({
        category: CommonConst.BOARD_ID_QNA,
        subCategory: CommonConst.SUB_BOARD_ID_QNA,
        searchKeyword: searchKeyword, 
        page: pageNo + 1,
        size: 20
      });

      setPaginationModel({
        pageSize: 20,
        page: pageNo,
        totalCount: result.totalCount
      });

      let lst = [];

      result.rows.forEach((item) => {
        lst.push({
          id: item.boardNo,
          boardNo: item.boardNo,
          title: item.title,
          createAt: item.createAt
        });
      });
      setRows(lst);
    }
    catch (e) {
      setError(e.message);
    }
    setLoading(false);
  }

  return (
    <div className="devman_tm_member">
      <div className="container">
        <div className="devman_tm_main_title" data-text-align="center">
          <h3>나의 질문과 답변</h3>
          <p>궁금하신 사항을 언제든 남겨주세요. 바로판다 관리자가 24시간 이내에 답변해드리겠습니다.</p>
        </div>
        <Box sx={{ height: 500, width: '100%', marginTop: '50px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <TextField
              size="small"
              variant="outlined"
              onChange={handleTextFieldChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  doRefresh({ pageNo: 0, searchKeyword: searchKeyword });
              }}}
              sx={{flex:1}}
              value={searchKeyword}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ display: showClearIcon }}
                      onClick={handleClear}
                    >
                      <Clear />
                    </InputAdornment>
                  )
                },
              }}
              
            />
            <Box sx={{display:'flex', flex:1, alignItems:'center', justifyContent:'flex-end'}}>
              <Button variant="outlined" onClick={() => navigate('/customer/qna/write')} >등록</Button>
            </Box>
            
          </Box>
          <DataGrid
            rows={rows}
            rowCount={paginationModel?.totalCount}
            paginationMode="server"
            loading={isLoading}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            onRowClick={(param) => {
              navigate('/customer/qna/' + param.row.boardNo);
            }}
            pageSizeOptions={[20]}
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => {
              // moveToNextPage(newModel.page);
              doRefresh({ pageNo: newModel.page });
            }}

          />
          

        </Box>
      </div>
    </div>
  );
});


export default QnaBoard;