import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import {
    Button,
    Box
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from 'react-router-dom'; 


const SignUpEnd = observer( ({ userDetail, onCancel }) => {
    const navigate = useNavigate();
    const [photoURL, setPhotoURL] = useState(null);
    const [nickName, setNickName] = useState(null);
    const [email, setEmail] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    useEffect(()=>{
        if(userDetail == null ) return;
        setPhotoURL(userDetail.photoURL);
        setEmail(userDetail.email);
        setNickName(userDetail.nickName);
    }, []);

    return (
        <Box sx={{
            width: '80%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}>
            {/* <h4>회원가입완료</h4> */}

            <Avatar  src={`data:image/png;base64, ${photoURL}`} 
                sx={{ width: 80, height: 80 }}
            />

            <h5>안녕하세요 {nickName}님. 회원가입이 완료되었습니다.</h5>
            <p>아이디 : {email}</p>

            
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '350px',
                justifyContent: 'center',
                marginTop: '50px',
                marginBottom: '50px',
            }}>
                <Button color="primary" variant="contained" onClick={()=>{navigate('/')}} >
                    홈으로
                </Button>
            </Box>
        </Box>
    );
});

export default SignUpEnd;