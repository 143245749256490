
export default function Page() {
  return (
    
      <div className="devman_tm_section" id="about">
        <div className="devman_tm_hero">
          <div className="background">
            <div className="image" data-img-url="img/hero/1.jpg" />
          </div>
          <div className="container">
            <div className="content">
              <div>
                HI
                HI
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}