import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { useFormik} from 'formik';
import {
    Button,
    Box,
    TextField
} from "@mui/material";

import { observer } from "mobx-react-lite";
import * as yup from "yup";
import { useAuthRepository } from "repository/RootProvider";

const validationSchema = yup.object({
    nick_name: yup
        .string('닉네임을 입력하세요')
        .required('닉네임을 입력하세요')
});

const NickName = observer( ({policyState, userDetail, setUserDetail, onSubmit }) => {
    const [photoURL, setPhotoURL] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const authRepository = useAuthRepository();

    useEffect(()=>{
        if(userDetail == null  ) return;

        setPhotoURL(userDetail.photoURL);
        if(userDetail.displayName != null) {
            formik.setFieldValue('nick_name', userDetail.displayName);
        }   
        else {
            if (userDetail.email != null) {
                formik.setFieldValue('nick_name', userDetail.email);
            }
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            nick_name: userDetail?.email || '',
        },
        validationSchema: validationSchema,
        onSubmit: async ( values) => {
            try {
                // 사용자의 이메일을 companyId로 사용합니다.
                userDetail.companyId = userDetail.email;
                userDetail.usageAgreeFlag = (policyState.policyChecked) ? 'Y':'N';
                userDetail.personalInfoFlag = (policyState.privacyChecked) ? 'Y':'N';
                userDetail.confirmFourteenFlag = (policyState.ageChecked) ? 'Y':'N';
                userDetail.eventMsgAcceptFlag = (policyState.marketingChecked) ? 'Y':'N';

                userDetail.avatar = photoURL;
                userDetail.nickName = values.nick_name;

                // 최종 로그인 ID를 LocalStorage에 저장합니다.
                await authRepository.setLastLoginUserInfo(userDetail);

                // 바로 판다 사용자 정보 Upsert를 Repository를 통해 요청합니다.
                await authRepository.doUpsertUserInfo(userDetail);

                // 사용자 정보를 업데이트 합니다.
                await authRepository.doRefreshUserInfo(
                    userDetail.companyId
                );

                if(onSubmit) onSubmit();
            }
            catch(error) {
                setErrorMsg(error);
            }
        },
    });

    return (
        <Box sx={{
            width: '80%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}>
            <h4>사용자 정보 입력하기</h4>

            서비스에서 사용할 닉네임을 입력하세요

            <Avatar  src={`data:image/png;base64, ${photoURL}`} 
                sx={{ width: 80, height: 80 }}
            />

            <TextField
                name="nick_name"
                sx={{ width: '350px' }}
                label="닉네임"
                variant="outlined"
                margin="normal"
                size="normal"
                value={formik.values.nick_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.nick_name && Boolean(formik.errors.nick_name)}
                helperText={formik.touched.nick_name && formik.errors.nick_name}
            />
            {(errorMsg) && <p style={{color:'red'}}>{errorMsg}</p>}

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '350px',
                justifyContent: 'flex-end',
                marginTop: '20px',
                marginBottom: '50px',
            }}>
                <Button color="primary" variant="contained" onClick={formik.handleSubmit} type="submit">
                    등록
                </Button>
            </Box>
        </Box>
    );
});

export default NickName;