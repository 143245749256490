import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from "mobx-react-lite";

import { useBoardRepository } from 'repository/RootProvider';
import QnaForm from './qna_form';


const QnaEdit = observer(() => {
    const navigate = useNavigate();
    const boardRepository = useBoardRepository();
    const [isLoading, setIsLoading] = useState(false);
    const [board, setBoard] = useState(null);
    const { idx } = useParams();

    useEffect(() => {
        if (idx == null) return;
        doGetContent();
    }, [idx]);


    const doGetContent = async () => {
        try {
            setIsLoading(true);
            let result = await boardRepository.getContentDetail({ boardNo: idx });
            // 게시물 리스트로 반환되어 0번째 데이터를 가져옵니다.
            let record = result.rows[0];
            setBoard(record);
        }
        catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    const onSubmit = async (values, attachFiles) => {   
        try {
            setIsLoading(true);
            let result = await boardRepository.edit({
                boardNo: Number.parseInt(idx),
                title: values.title,
                contents: values.contents,
                fileList: attachFiles
            });
            alert('질문이 수정되었습니다.');
            navigate(-1);
        }
        catch (e) {
            alert(e);
        }
        setIsLoading(false);
    }

    return (
        <div className="devman_tm_member">
            <div className="container">
                <div className="devman_tm_main_title" data-text-align="center">
                    <h3>나의 질문과 답변</h3>
                    <p>궁금하신 사항을 언제든 남겨주세요. 바로판다 관리자가 24시간 이내에 답변해드리겠습니다.</p>
                </div>
                <Box sx={{ height: 500, width: '100%', marginTop: '50px' }}>
                    <QnaForm isLoading={isLoading} board={board} onSubmit={onSubmit} />
                </Box>
            </div>
        </div>

    );
});

export default QnaEdit;