import { Icon, Button, Box } from "@mui/material";
// import { ReactComponent as Logo } from "/img/svg/apple/Logo - SIWA - Left-aligned - Black - Large.svg";
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        paddingLeft: 56,
        paddingRight: 56
    },
    rootAbsolute: {
        background: '#fff',
        height: 50,
        width : 250,
        // boxShadow:3,
        paddingLeft: 66,
        paddingRight: 56,
        position: "relative",

        '&:hover': {
            backgroundColor: '#fff',
            color: '#3c52b2',
        },

        "& .MuiButton-sizeSmall": {
            paddingLeft: 48,
            paddingRight: 32,
            position: "absolute",
            left: 16
        },
        "& .MuiButton-startIcon": {
            position: "absolute",
            left: 25
        }
    },
    rootNegativeMargin: {
        paddingLeft: 56,
        paddingRight: 56,
        "& .MuiButton-startIcon": {
            marginLeft: -46,
            marginRight: 36
        }
    }
});

const EmailSignInBtn = ({onClick}) => {
    const classes = useStyles();
    return (
        <Button
            component="label"
            onClick={onClick}
            // role={undefined}
            variant="outlined"
            startIcon={<MarkunreadOutlinedIcon />}
            className={classes.rootAbsolute}>
            이메일로 시작하기
        </Button>
    )
}

export default EmailSignInBtn;