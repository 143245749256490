import { makeAutoObservable } from "mobx"

class PandaUserRepository {
    company_id = null;


    constructor() {
        makeAutoObservable(this)
    }

    initState() {
        this.company_id = null
    }

    setUserDetail(user) {
        this.company_id = user.company_id;
    }
}

export default PandaUserRepository;