import { useEffect, useState } from 'react';
import RegisterStep from './register_step';
import PolicyAccept from './policy_accept';
import SignUpSocial from './signup_social';
import SignInEmail from './signup_email';
import NickName from './nick_name';
import SignUpEnd from './signup_end';
import {Box} from "@mui/material";

const SignUpMain = () => {
  const [step, setStep] = useState("1");
  const [userDetail, setUserDetail] = useState(null);
  const [policyState, setPolicyState] = useState(null);

  useEffect(()=>{
    if(userDetail == null ) return; 
    console.log("User Detail: ", userDetail); 
  }, [userDetail] );

  return (
    <div className="devman_tm_member">
      <div className="container">
        <div className="devman_tm_main_title" data-text-align="center">
          <h3>회원가입 </h3>
          <p>비즈니스 플랜을 한달간 무료 체험하세요</p>
          <p>결제는 써보고 결정하세요</p>
        </div>
        <RegisterStep step={step} moveTo={(step)=>{setStep(step)}} />
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          border: '1px solid #ccc',
          // bgcolor: '#ccc', 
          height: '40vh',
          marginTop: '30px', borderRadius: '10px',
          padding: '30px',
          display: 'flex',
          flexDirection: 'column',
        }}>
          {(step == "1") &&
            <PolicyAccept 
              setPolicyState={setPolicyState} 
              onSubmit={() => { setStep("2") }} />}
          {(step == "2") &&
            <SignUpSocial 
              setUserDetail={setUserDetail}
              moveToEmailSignUp={() => { setStep("2-1") }}
              onSubmit={() => { setStep("3") }} />
          }
          {(step == "2-1") &&
            <SignInEmail
              onCancel={(step)=>{setStep(step)}}
              setUserDetail={setUserDetail}
              onSubmit={() => { setStep("3") }} 
            />
          }
          {(step == "3") &&
            <NickName  
              policyState={policyState}
              userDetail={userDetail} 
              setUserDetail={setUserDetail}
              onSubmit={() => { setStep("4") }} 
              />
          }
          {(step == "4") &&
            <SignUpEnd  
              policyState={policyState}
              userDetail={userDetail} />
          }
        </Box>
      </div>
    </div>
  );
}



export default SignUpMain;