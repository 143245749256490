import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { OAuthProvider } from "firebase/auth";
import { getMessaging } from "firebase/messaging";

const apple_provider = new OAuthProvider('apple.com');
apple_provider.addScope('email');
apple_provider.addScope('name');    

const firebaseConfig = {
    apiKey: "AIzaSyDt5GbiW1m5BRNoWf43zav2PVvD3ghFVEs",
    authDomain: "baropanda-auth.firebaseapp.com",
    projectId: "baropanda-auth",
    storageBucket: "baropanda-auth.appspot.com",
    messagingSenderId: "455398620322",
    appId: "1:455398620322:web:ba0cdc5a47159a543b2bfb",
    measurementId: "G-SD0KZJQYHF"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const google_auth = getAuth(app);
const apple_auth = getAuth();

export { messaging, google_auth, apple_auth, apple_provider };