import { getToken } from "firebase/messaging";
import { messaging } from 'firebase_config';
import { convertImgToBase64URL } from 'utils/file_util';


export const firebaseErrorMsg = (error) => {
    // 에러 코드에 대한 안내 문구 반환하기
    // 사전 유효성 검증 여부 등을 고려해 발생 빈도 순으로 분기처리하는게 좋다.
    switch (error.code) {
        case "auth/user-not-found" || "auth/wrong-password":
            return "이메일 혹은 비밀번호가 일치하지 않습니다.";
        case "auth/email-already-in-use":
            return "이미 사용 중인 이메일입니다.";
        case "auth/weak-password":
            return "비밀번호는 6글자 이상이어야 합니다.";
        case "auth/network-request-failed":
            return "네트워크 연결에 실패 하였습니다.";
        case "auth/invalid-email":
            return "잘못된 이메일 형식입니다.";
        case "auth/popup-closed-by-user" :
            return "로그인을 취소하였습니다. 다시 시도해 주세요.";
        case "auth/internal-error":
            return "잘못된 요청입니다.";
        default:
            return "로그인에 실패 하였습니다.";
    }
}


/**
 * Firebase Login 후 
 * 사용자 프로필을 작성하는 로직입니다. 
 * 
 * 1) user 객체의 Access Token을 Cookie에 저장 합니다.
 * 2) device Token을 저장합니다. 
 * 3) 사용자 프로필을 가져옵니다. 
 * @param {*} user 
 */
export const getUserProfile = async (user)  => {
    try {
       

        // console.log("user is ", user.accessToken);
        // deviceToken을 가져옵니다.
        user.deviceToken = await getDeviceToken();

        // 사용자 프로필을 가져옵니다.
        let photoURL = user.photoURL;
        if(photoURL == null) {
            photoURL = 'img/empty_profile.png';
        }
        user.photoURL = await convertImgToBase64URL(photoURL);

        return user;
    }
    catch(error) {
        throw error;
    }
}

/**
  * Get Message Device Token 
  * @returns 
  */
export const getDeviceToken = async () => {
    const key = 'BLbPNr3yutEq5bLf49YUObKzLS32bMOzcdZTNCbBcIvNQpaG_UmUtgF3r9uU8eNbv1DxBgy87-lpBl2HpOZHJ6k';
    let token = "";
    try {
        let permission = await Notification.requestPermission();
        if (permission === 'granted') {
            token = await getToken(messaging, { vapidKey: key });
        }
        return token;
    }
    catch (err) {
        console.log(err);
    }
}

