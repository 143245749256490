'use client'

import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { scrollSection, stickyNav } from "utils/utils";
import EzHashLink from "components/ez_hash_link";
import { HashLink } from 'react-router-hash-link';
import { useAuthRepository } from "repository/RootProvider";
import { observer } from "mobx-react-lite";
import Avatar from '@mui/material/Avatar';

const MobileHeader = observer(() => {
  const [toggle, setToggle] = useState(false);
  const authRepository = useAuthRepository();
  const location = useLocation();
  const { hash, pathname, search } = location;
  
  useEffect(()=>{
    window.addEventListener("scroll", stickyNav);
  }, [] ); 

  return (
    <div className="devman_tm_mobile_menu">
      <div className="mobile_menu_inner">
        <div className="mobile_in">
          <div className="logo">
            <Link to="/">
              <img src="img/logo/logo_374_116.png" alt="" />
            </Link>
          </div>
          <div className="trigger">
            <div
              className={`hamburger hamburger--slider ${toggle ? "is-active" : ""
                }`}
            >
              <div className="hamburger-box" onClick={() => setToggle(!toggle)}>
                <div className="hamburger-inner" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="dropdown"
        style={{
          display: toggle ? "block" : "none",
        }}
      >
        <div className="dropdown_inner">
          <ul className="anchor_nav">
            <EzHashLink to="/#home" label="Home" current_path={pathname + hash} />
            <EzHashLink to="/#maror_features" label="기능소개" current_path={pathname + hash} />
            <EzHashLink to="/#price" label="요금안내" current_path={pathname + hash} />
            <EzHashLink to="/customer" label="고객지원"  current_path={pathname + hash} />
            { 
            (authRepository.user != null)
            ? <Link to="/profile"> 
                <Avatar src={`data:image/png;base64, ${authRepository.user?.avatar}`}  sx={{ width: 40, height: 40 }} />
              </Link>
            :<li className="download_cv">
              <HashLink to="/login">로그인</HashLink>
            </li>
            }            
          </ul>
        </div>
      </div>
    </div>
  );
});
export default MobileHeader;
