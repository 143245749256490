import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { google_auth } from 'firebase_config';
import { sendPasswordResetEmail } from 'firebase/auth';
import { firebaseErrorMsg } from 'utils/firebase_util';

function ConfirmDialog(props) {
    const { onClose, email, open } = props;
    const [isSend, setIsSend] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    useEffect(() => {
        setErrorMsg(null);
    }, [props]);

    useEffect( ()=>{
        if( open==undefined) return;
        setIsSend(!open);
    }, [open]);

    const handlePasswordReset = async (values) => {
        try {
            setErrorMsg(null);
            setIsSend(false);
            // 비밀번호 재설정 이메일 보내기
            let result = await sendPasswordResetEmail(google_auth, email)
            setErrorMsg("비밀번호 재설정 링크를 발송했습니다. 이메일을 확인해주세요.");
            setIsSend(true);
        }
        catch (error) {
            console.log("Error", error,)
            setErrorMsg(firebaseErrorMsg(error));
        }
    }

    return (
        <Dialog fullWidth={true} maxWidth={'sm'} onClose={onClose} open={open}>
            {/* <DialogTitle>비빌번호찾기</DialogTitle> */}
            {/* <DialogContent>로 임시 비밀번호를 발송합니다. </DialogContent> */}
            <Box sx={{
                display: 'flex', flexDirection: 'column',
                marginTop: '50px', marginBottom: '50px',
                justifyContent: 'space-between', alignItems: 'center'
            }}>
                <h5>비밀번호 재설정 링크를 발송 합니다</h5>
                <p>이메일 : {email}</p>  
                { errorMsg && <p>{errorMsg}</p> }
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '30px',
                    }}
                >
                    <Button onClick={() => handlePasswordReset()}>
                        {isSend? "재발송" : "발송"}
                    </Button>
                    <Button onClick={onClose}>
                        닫기
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
}

ConfirmDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    //   selectedValue: PropTypes.string.isRequired,
};

export default ConfirmDialog;
