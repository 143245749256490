import { useState } from "react";
import SplashScreen from "components/SplashScreen";
import App from "App";


const Intro = () => {
  const [loading, setLoading] = useState(true);

  const finishLoading = () => {
    setLoading(false);
  };

  return (
    <>
    {
        loading ? <SplashScreen finishLoading={finishLoading} /> : <App />
    }
    </>
  );
};
export default Intro;