'use client'

import { Fragment, useState } from "react";
import ServicePopup from "./popup/ServicePopup";

const serviceData = [
  {
    name: "비지니스 1개월 플랜",
    icon: "img/svg/phone.svg",
    price : '50,000(VAT 별도)/월',
    img: "img/service/2.jpg",
    description: [
      "시장분석 무제한",
      "상품정보 자동생성 무제한",
      "마켓 상품 연동 무제한",
    ],
  },
  {
    name: "비지니스 6개월 플랜",
    icon: "img/svg/phone.svg",
    price : '270,000(VAT 별도)',
    img: "img/service/2.jpg",
    description: [
      "10% 할인된 금액",
      "시장분석 무제한",
      "상품정보 자동생성 무제한",
      "마켓 상품 연동 무제한",
    ],
  }
];
const Prices = () => {
  const [activeData, setActiveData] = useState({});
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      {/* <ServicePopup
        open={open}
        close={() => setOpen(false)}
        data={activeData}
      /> */}
      <div className="devman_tm_section" id="price">
        <div className="devman_tm_service">
          <div className="container">
            <div className="devman_tm_main_title" data-text-align="center">
              <h3>이용요금 </h3>
              <p>비즈니스 플랜을 한달간 무료 체험하세요</p>
              <p>결제는 써보고 결정하세요</p>
            </div>
            <div>

            </div>
            <div className="service_list">
              <ul>
                {serviceData.map((service, i) => (
                  <li
                    className={`wow ${i % 2 ? "fadeInLeft" : "fadeInRight"}`}
                    data-wow-duration="1s"
                    key={i}>
                    <div className="list_inner">
                      <img className="svg" src={service.icon} alt="" />
                      <h3 className="title">{service.name}</h3>
                      <h3 className="title">{service.price}</h3>
                      <div className="text">
                        {/* {service.description[0].substring(0, 138)}. */}
                        {  service.description.map( (text, j) => (
                          <div key={j}>{text}</div>
                        ))}
                      </div>
                     
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div
            className="shape moving_effect"
            data-direction="y"
            data-reverse="yes"
          />
        </div>
      </div>
    </Fragment>
  );
};
export default Prices;
