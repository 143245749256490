import React from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline } from '@mui/material';
import './styles/global.css';
import Intro from 'intro';
import { RootProvider } from 'repository/RootProvider'
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RootProvider>
      <BrowserRouter>
        <CssBaseline />
        <Intro />
      </BrowserRouter>
    </RootProvider>
  </React.StrictMode>
);

