import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ErrorMessage } from 'formik';

const EzCheckBox = ({
  label,
  name, 
  checked,
  onChange }) => {
  return (
    <div>
    <FormControlLabel
      control={
        <Checkbox
          onChange={onChange}
          name={name}
          checked={checked}
        />
      }
      label={label}
    />
    <ErrorMessage name={name} component="div" style={{color:'red'}}/>
    </div>
  );
}

export default EzCheckBox;