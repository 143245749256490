import { useEffect, useState } from 'react';
import {
  Button,
  Box,
  TextField,
  Badge
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from 'formik';
import { useAuthRepository } from "repository/RootProvider";
import Avatar from '@mui/material/Avatar';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import { grey } from '@mui/material/colors';
import { URLConst } from 'constant/URLConst';
import { doPostJson } from 'utils/HttpUtils';
import { observer } from "mobx-react-lite";
import { Link } from 'react-router-dom';

const validationSchema = yup.object({
  nick_name: yup
    .string('닉네임을 입력하세요')
    .required('닉네임을 입력하세요')
});

const Profile = observer( () => {
  const authRepository = useAuthRepository();
  const [errorMsg, setErrorMsg] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [photoURL, setPhotoURL] = useState('');
  
  useEffect(() => {
    if(authRepository.user == null) return; 

    setPhotoURL(authRepository.user.avatar);
    setCompanyId(authRepository.user.companyId);
  }, [authRepository.user]);

  const formik = useFormik({
    initialValues: {
      nick_name: authRepository.user?.nickName || '',
      // photoURL: ,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        let user = new Map();
        user.set("companyId", authRepository.user.companyId);
        user.set("avatar", photoURL);
        user.set("nickName", values.nick_name);

        let result = await doPostJson(
          URLConst.PANDA_USER_URL,
          'PUT',
          user
        );

        authRepository.doRefreshUserInfo(companyId);
      }
      catch (error) {
        setErrorMsg(error);
      }
    },
  });




  const handleFileUpload = (event) => {
    try {
      const file = event.target.files[0];
      //FileReader 객체 생성
      const fReader = new FileReader();
      fReader.readAsDataURL(file);
      fReader.onloadend = function (event) {
        const path = event.target.result;
        let lst = path.split(',');
        setPhotoURL(lst[1]);
      }
    }
    catch (error) {
      console.log(error);
    }
  }


  return (
    <div className="devman_tm_member">
      <div className="container">
        <div className="devman_tm_main_title" data-text-align="center">
          <h3>프로필 수정 </h3>
          <p>바로판다 대표 프로필과 별명을 수정할 수 있습니다</p>
        </div>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          border: '1px solid #ccc',
          // bgcolor: '#ccc', 
          height: '40vh',
          marginTop: '30px', borderRadius: '10px',
          padding: '30px',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Button
            component="label"
            cursor="pointer">
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                <PhotoCameraOutlinedIcon sx={{ color: grey[500] }} />
              }
            >
              <Avatar src={`data:image/png;base64, ${photoURL}`}
                sx={{ width: 80, height: 80 }}
                onChange={handleFileUpload}
              />
            </Badge>
            <input type="file" accept=".gif,.jpg,.jpeg,.png" hidden onChange={handleFileUpload} />
          </Button>

          <TextField
            name="nick_name"
            sx={{ width: '350px' }}
            label="닉네임"
            variant="outlined"
            margin="normal"
            size="normal"
            value={formik.values.nick_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.nick_name && Boolean(formik.errors.nick_name)}
            helperText={formik.touched.nick_name && formik.errors.nick_name}
          />
          {(errorMsg) && <p style={{ color: 'red' }}>{errorMsg}</p>}

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '350px',
            justifyContent: 'space-between',
            marginTop: '50px',
            marginBottom: '20px',
          }}>
            <Link to="/profile/sign_out">회원탈퇴</Link>
            <Button color="primary" variant="contained" onClick={formik.handleSubmit} type="submit">
              저장
            </Button>
          </Box>
        </Box>

      </div>
    </div>
  );
});

export default Profile;