import { google_auth, apple_auth, apple_provider } from 'firebase_config';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { Fragment, useState } from 'react';
import GoogleSignInBtn from 'components/button/google_signin_btn';
import AppleSignInBtn from 'components/button/apple_signin_btn';
import EmailSignInBtn from 'components/button/email_signin_btn';
import { Divider, Box } from '@mui/material';
import { firebaseErrorMsg, getUserProfile } from 'utils/firebase_util';
import { HashLink } from 'react-router-hash-link';

const LogInSocial = ({
    moveToEmailSignIn,
    onSubmit
}) => {
    
    const [errorMsg, setErrorMsg] = useState(null);
    /**
     * 구글 로그인
     */
    const handleGoogleLogin = async () => {
    
        try {
            const provider = new GoogleAuthProvider(); // provider를 구글로 설정
            // 구글 자동 로그인 비활성화 
            provider.setCustomParameters({
                prompt: 'select_account'
            });
            setErrorMsg(null);
            let result = await signInWithPopup(google_auth, provider); // popup을 이용한 signup
            let user = await getUserProfile(result.user);
            if (onSubmit) onSubmit(user);
        }
        catch (error) {
            setErrorMsg(firebaseErrorMsg(error));
        }
    }

    /**
     * 애플 로그인 
     */
    const handleAppleLogin = async () => {
        try {
            setErrorMsg(null);
            let result = await signInWithPopup(apple_auth, apple_provider);
            let user = await getUserProfile(result.user);
            // setUserDetail(user);
            if (onSubmit) onSubmit(user);
        }
        catch (error) {
            setErrorMsg(firebaseErrorMsg(error));
        }
    }

    return (
        <Box sx={{
            width:'80%',
            height:'100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '50px',
            marginBottom: '50px',
          }}>
            {/* 간편 로그인 */}
            <GoogleSignInBtn onClick={handleGoogleLogin} />
            {/* {userData ? userData.displayName : null} */}
            <AppleSignInBtn onClick={handleAppleLogin} />
            {/* {userData ? userData.displayName : null} */}
            {(errorMsg) && <p style={{ color: 'red' }}>{errorMsg}</p>}

            <Divider variant="middle" sx={{ width: '60%' }} />
            또는
            <EmailSignInBtn onClick={() => {
                moveToEmailSignIn();
            }} />

            <p>바로판다가 처음이신가요? <HashLink to="/signup">회원가입</HashLink></p>
       </Box>
    );
}

export default LogInSocial;