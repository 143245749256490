import PolicyPopup from "components/popup/PolicyPopup";
import {useState,  Fragment } from "react";


const Copyright = () => {

  const [popup, setPopup] = useState(false);
  const [srcUrl, setSrcUrl] = useState('');
  
  const showPopup = (url) => {
    setSrcUrl(url);
    setPopup(true);
  }

  return (
    <Fragment>
      <PolicyPopup src_url={srcUrl} open={popup} close={() => setPopup(false)} />
    
      <div className="devman_tm_copyright_area">
        <div className="devman_tm_section">
          <div className="devman_tm_copyright">
            <div className="container">
              <div className="inner">
                <div className="left wow fadeInLeft" data-wow-duration="1s">
                  <p>
                    (주)라운드유 대표이사 현분희
                  </p>
                  <p>사업자등록번호 651-88-03031 통신판매업신고 제 2024-서울마포-2362호</p>
                  <p>
                    04071 서울특별시 마포구 성지길 25-11, 지층 1057호
                  </p>
                  <p>
                    고객센터 <a href="mailto:panda@roundu.co.kr">panda@roundu.co.kr</a>
                  </p>
                  <p>ⓒ Since 2024 ROUND U Corp. All right reserved.</p>
                </div>
                <div className="right wow fadeInRight" data-wow-duration="1s">
                  <ul>
                    {/* <li>
                    <a href="/about">회사소개</a>
                  </li> */}
                    <li>
                      <a onClick={() => showPopup("/policy/policy-ko.html") } >이용약관</a>
                    </li>
                    <li>
                    <a onClick={() => showPopup("/policy/privacy-ko.html") }>개인정보처리방침</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Copyright;
