import { makeAutoObservable } from "mobx"
import {URLConst} from 'constant/URLConst';
import { doGetJson, doPostJson } from 'utils/HttpUtils';
import CommonConst from "constant/CommonConst";

class BoardRepository {
    company_id = null;

    constructor(root) {
        makeAutoObservable(this)
        this.root = root;
        this.authRepository = root.authRepository;
    }
ß
    initState() {
        this.company_id = null
    }

    async getContentList  ({
        category = "", 
        subCategory = "",
        searchKeyword = "",
        page = 1,
        size = 20
    }) {
        try {
            let param = new Map();
            param.set("category", category);
            param.set("subCategory", subCategory);
            if(searchKeyword !== "")
                param.set("title", searchKeyword);
            param.set("page", page);
            param.set("size", size);

            let result = await doGetJson(
                URLConst.BOARD_LIST_URL, 
                'GET',
                param
            );
            
            return result;
        } catch (error) {
            throw error;
        }
    }
    
    async getContentDetail  ({
        boardNo = ""
    }) {
        try {
            let param = new Map();
            param.set("boardNo", boardNo);

            let result = await doGetJson(
                URLConst.BOARD_DETAIL_URL, 
                'GET',
                param
            );
            
            return result;
        } catch (error) {
            throw error;
        }
    }


    async insert({
        title = "", 
        contents = "",
        fileList = [],
    }) {
        try {
            const user = this.authRepository.user;
            let content = new Map();
            content.set("category", CommonConst.BOARD_ID_QNA);
            content.set("subCategory", CommonConst.SUB_BOARD_ID_QNA);
            content.set("title", title);
            content.set("content",  contents);
            content.set("companyId", user.companyId);

            if(fileList.length > 0)
                content.set("attachFiles", fileList);
            
            let result = await doPostJson(
                URLConst.BOARD_POST_URL,
                'POST',
                content
            );
            return result;
        }
        catch(e) {
            throw e;
        }
    }

    async edit({
        boardNo = -1,
        title = "", 
        contents = "",
        fileList = [],
    }) {
        try {
            const user = this.authRepository.user;
            let content = new Map();
            content.set("boardNo", boardNo);
            content.set("category", CommonConst.BOARD_ID_QNA);
            content.set("subCategory", CommonConst.SUB_BOARD_ID_QNA);
            content.set("title", title);
            content.set("content",  contents);
            content.set("companyId", user.companyId);

            if(fileList.length > 0)
                content.set("attachFiles", fileList);
            
            let result = await doPostJson(
                URLConst.BOARD_POST_URL,
                'PUT',
                content
            );
            // Update의 경우 결과가 없습니다.

            return result;
        }
        catch(e) {
            throw e;
        }

    }

    async delete({
        boardNo = -1
    }) {
        try {
            let param = new Map();
            param.set("boardNo", boardNo);

            let result = await doGetJson(
                URLConst.BOARD_POST_URL,
                'DELETE',
                param
            );
            return result;
        }
        catch(e) {
            throw e;
        }
    }

}

export default BoardRepository;