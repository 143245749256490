// components/SplashScreen.js
import React, { useEffect } from 'react';

const SplashScreen = ({ finishLoading }) => {
  useEffect(() => {
    let preloader = document.getElementById("preloader");
    const preloaded = () => {
      return new Promise ( (resolve, rejects) =>{
        setTimeout(function () {
          preloader.classList.add("preloaded");
        }, 800);
        setTimeout(function () {
          // preloader.remove();
          resolve('done');
        }, 2000);
      } );
    }

    preloaded().then(finishLoading)

  }, []);

  return (
    <div id="preloader">
      <div className="loader_line" />
    </div>
  );
};

export default SplashScreen;