import Popup from "./Popup";

// import { readFileSync } from 'fs';
// import { join } from 'path';


const PolicyPopup = ({ open, close, src_url }) => {
  // const text = readFileSync(join("/policy", 'policy-ko.html'), 'utf8');
  // const html_str = text;
  return (
    <Popup open={open} close={close}>
      {/* <div className="popup_details">
        <div dangerouslySetInnerHTML={{ __html:  html_str}} />
      </div> */}
      <iframe src={src_url}  width="100%" ></iframe>
    </Popup>
  );
};
export default PolicyPopup;