import React, { createContext, useContext } from "react";
import RootStore from 'repository/RootStore'

const rootContext = createContext();

export const RootProvider = ({ children }) => {
    let store = new RootStore();
    return (
        <rootContext.Provider value={store}>
            {children}
        </rootContext.Provider>
    );
}

// create the hook
export const useRootStore = () => {
    const context = useContext(rootContext)
    if (context === undefined) {
        throw new Error("useRootStore must be used within RootStoreProvider")
    }
    return context
}

export const useAuthRepository = () => {
    const store = useRootStore();
    return store.authRepository;
}

export const usePandaUserRepository = () => {
    const store = useRootStore();
    return store.pandaUserRepository;
}

export const useBoardRepository = () => {
    const store = useRootStore();
    return store.baroRepository;
}

