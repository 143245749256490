
import CounterSection from "components/CounterSection";


import MajorFeatures from "components/MajorFeatures";
import Hero from "components/Hero";
import Prices from "components/Prices";

// import Mouse from "@/app/layouts/Mouse";
import ScrollTop from "components/ScrollTop";

const Main = () => {
  return (
    <>
      <Hero/>
      <CounterSection/>
      <MajorFeatures/>         
      <Prices/>
    </>
  );
};
export default Main;