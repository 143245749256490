import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import { useBoardRepository } from 'repository/RootProvider';

const columns = [
  { field: 'boardNo', headerName: 'No', width: '100' },
  {
    field: 'title',
    headerName: '제목',
    // width: '60%',
    flex : 1, 
    editable: true,
  },
  {
    field: 'createAt',
    headerName: '작성일자',
    width: '200',
    editable: true,
  }
];

const NoticeBoard = () => {
  const boardRepository = useBoardRepository();
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(null);

  useEffect(()=>{
    doRefresh();
  }, []);

  const doRefresh = async (searchKeyword) => {
    try {
      let result = await boardRepository.getContentList({
        category: "0000",
        subCategory: "0001",
        page: 1,
        size: 20
      });
      console.log("Result ", result.rows);
      let lst = [...rows];

      result.rows.forEach((item) => {
        lst.push({
          id: item.boardNo,
          boardNo: item.boardNo,
          title: item.title,
          createAt: item.createAt
        });
      });
      setRows(lst);
      // setRows(result?.rows);
    }
    catch(e) {
      setError(e.message);
    }
  }

  return (
    <div className="devman_tm_member">
      <div className="container">
        <div className="devman_tm_main_title" data-text-align="center">
          <h3>바로판다 공지사항</h3>
          <p>바로판다의 새로운 소식과 상품을 소개해 드립니다</p>
        </div>
        <Box sx={{ height: 400, width: '100%', marginTop:'50px'}}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
            // slots={{
            //   toolbar: CustomToolbar,
            // }}
          />
        </Box>
      </div>
    </div>
  );
}

const CustomToolbar = ()=>{
  return (
    <GridToolbarContainer>
      {/* <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector
        slotProps={{ tooltip: { title: 'Change density' } }}
      /> */}
      <Box sx={{ flexGrow: 1 }} />
      {/* <GridToolbarExport
        slotProps={{
          tooltip: { title: 'Export data' },
          button: { variant: 'outlined' },
        }}
      /> */}
      {/* <Button>등록</Button> */}
    </GridToolbarContainer>
  );
}

export default NoticeBoard;