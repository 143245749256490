'use client'
import { HashLink } from 'react-router-hash-link';
const homeData = {
  firstName: "James",
  lastName: "Smith",
  designation: "Web Developer",
  address: "New York",
  img: "img/hero/2.jpg",
  bio: "Jodi kokhono vul hoye jai tumi oporadh nio na ptate velit esse cillum dolore",
  numberOfProject: 800,
  numberofyear: 12,
};

const Hero = () => {
  return (
    <div className="devman_tm_section" style={{paddingTop:'0px'}} id="home">
      <div className="devman_tm_hero">
        <div className="background">
          <div className="image" data-img-url="img/hero/hero_bg.png" />
        </div>
        <div className="container">
          <div className="content">
            <div className="left">
              <div className="inner">
                <h3 className="hello">마케팅, 디자인, 상품등록</h3>
                <h3 className="name">AI 바로판다</h3>
                {/* <h3 className="job">당근처럼 쉽게 상품팔기</h3> */}
                <h3 className="job">
                  온라인 상품판매도 당근처럼 쉽게 하기
                </h3>
                <p className="text">
                  생성형 AI 바로판다가 사진 한장으로
                </p>
                
                <p className="text">
                  시장분석, 상품명, 키워드 추천, 상품 페이지를
                </p>
                <p className="text">
                  자동으로 만들어 드립니다.
                </p>
                <div className="buttons">
                  <div className="devman_tm_button">
                    <HashLink to="/signup">
                      바로판다 체험하기
                    </HashLink>
                  </div>
                  {/* <div className="simple_button">
                    <a className="anchor" href="#about">
                      About Me
                    </a>
                  </div> */}
                </div>
              </div>
              {/* <h3 className="stroke_1">{homeData.firstName}</h3>
              <h3 className="stroke_2">{homeData.lastName}</h3> */}
            </div>
            <div className="right">
              <div className="image">
                <img src="img/thumbs/53-61.jpg" alt="" />
                <div className="main" style={{backgroundImage:`url("img/hero/2.jpg")`}}  data-img-url={homeData.img} />
                {/* <span className="win">
                  <img src="img/svg/award.svg" alt="" className="svg" />
                </span> */}
                {/* <div className="numbers year">
                  <div className="wrapper">
                    <h3>
                      <Counter end={homeData.numberofyear} />
                    </h3>
                    <span className="item_name">
                      Years of
                      <br />
                      Success
                    </span>
                  </div>
                </div> */}
                {/* <div className="numbers project">
                  <div className="wrapper">
                    <h3>
                      <Counter end={homeData.numberOfProject} />
                      <span className="extra">+</span>
                    </h3>
                    <span className="item_name">
                      Projects
                      <br />
                      Completed
                    </span>
                  </div>
                </div> */}
                {/* <span className="circle anim_circle">
                  <img src="img/hero/circle.png" alt="" />
                </span> */}
              </div> 
            </div>
          </div>
        </div>
       </div>
     </div>
  );
};
export default Hero;
