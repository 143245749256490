import { Fragment, useState } from "react";
// import DetailsPopup from "./popup/DetailsPopup";

const MajorFeatures = () => {
  // const [popup, setPopup] = useState(false);
  return (
    <Fragment>
      {/* <DetailsPopup open={popup} close={() => setPopup(false)} /> */}
      <div className="devman_tm_section" id="maror_features">
        <div className="devman_tm_portfolio">
          <div className="container">
            <div className="devman_tm_main_title" data-text-align="center">
              <span>판매를 쉽게! 찍고 바로판다</span>
              <h3>상품페이지 자동생성 & 마켓 연동</h3>
              <p>상품페이지를 만들 때 </p><p>많은 시간과 비용을 투자하고 계시나요</p>
              <p>그렇다면 이제 걱정하지 마세요!</p><p>바로판다가 여러분을 도와드릴 거예요.</p>
            </div>
            <div className="portfolio_list">
              <ul>
                <li className="wow fadeInUp" data-wow-duration="2s">
                  <div className="list_inner">
                    <div
                      className="background_image"
                      data-img-url="img/features/major_features_bg.png"
                    />
                    <div className="content">
                      <div className="details">
                        <span className="category">
                          <a href="#">시장분석</a>
                        </span>
                        <p className="title">
                          유사 상품 판매현황
                        </p>
                        <ul>
                          <li>현재 온라인에서 판매되고 있는 </li>
                          <li>유사 상품의 종류, 판매몰, 등록상품개수 분석</li>
                          <li>최저가, 최고가, 최빈가 분석을 통한 가격정보</li>
                          <li>유사 상품의 등록 카테고리, 키워드 분석 </li>
                        </ul>
                      </div>
                    </div>
                    <div className="overlay" />
                    <a
                      className="devman_tm_full_link popup-youtube"
                      href="https://www.youtube.com/watch?v=7e90gBu4pas"
                    />
                  </div>
                </li>
                <li className="wow fadeInUp" data-wow-duration="2s">
                  <div className="list_inner">
                    <div
                      className="background_image"
                      data-img-url="img/features/major_features_bg.png"
                    />
                    <div className="content">
                      <div className="details">
                        <span className="category">
                          <a href="#">상품정보생성</a>
                        </span>
                        <p className="title">
                          인공지능이 상품정보를 생성해 드립니다. 
                        </p>
                        <ul>
                          <li>상품명</li>
                          <li>최적 카테고리</li>
                          <li>가성비 갑 키워드</li>
                          <li>구매를 유도하는 홍보 문구</li>
                        </ul>
                      </div>
                    </div>
                    <div className="overlay" />
                    <a
                      className="devman_tm_full_link popup-youtube"
                      href="https://www.youtube.com/watch?v=7e90gBu4pas"
                    />
                  </div>
                </li>
                <li className="wow fadeInUp" data-wow-duration="1s">
                  <div className="list_inner">
                    <div
                      className="background_image"
                      data-img-url="img/features/major_features_bg.png"
                    />
                    <div className="content">
                      <div className="details">
                        <span className="category">
                          <a href="#">상품페이지 자동생성</a>
                        </span>
                        <h3 className="title">
                          내가 찍은 사진으로 만드는 상품페이지
                        </h3>
                        <ul>
                          <li>목록이미지</li>
                          <li>AI가 자동 생성해주는 상품페이지</li>
                        </ul>
                      </div>
                    </div>
                    <div className="overlay" />
                    <a
                      className="devman_tm_full_link popup-vimeo"
                      href="https://vimeo.com/337293658"
                    />
                  </div>
                </li>
                <li className="wow fadeInUp" data-wow-duration="1s">
                  <div className="list_inner">
                    <div
                      className="background_image"
                      data-img-url="img/features/major_features_bg.png"
                    />
                    <div className="content">
                      <div className="details">
                        <span className="category">
                          <a href="#">마켓연동</a>
                        </span>
                        <h3 className="title">
                          복잡한 정보도 간단하게 등록 가능 
                        </h3>
                        {/* <ul>
                          <li>마진이 얼마나 된다고 따로 돈들여서 상품페이지를 만드나요</li>
                          <li>핸드폰으로 사진만 잘 찍으면, 잘팔리는 상품페이지는 바로판다가 만들어 드릴께요 </li>
                        </ul> */}
                      </div>
                    </div>
                    <div className="overlay" />
                    <a
                      className="devman_tm_full_link soundcloude_link mfp-iframe audio"
                      href="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/471954807&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                    />
                  </div>
                </li>
                {/* <li className="wow fadeInUp" data-wow-duration="1s">
                  <div className="list_inner">
                    <div
                      className="background_image"
                      data-img-url="img/portfolio/4.jpg"
                    />
                    <div className="content">
                      <div className="details">
                        <span className="category">
                          <a href="#">Details</a>
                        </span>
                        <h3 className="title">
                          <a href="#">
                            Web Application for
                            <br /> Desiverse
                          </a>
                        </h3>
                        <span className="view_project">
                          <a href="#">
                            View Project <i className="icon-right-big" />
                          </a>
                        </span>
                      </div>
                    </div>
                    <div className="overlay" />
                    <a
                      className="devman_tm_full_link portfolio_popup c-pointer"
                      onClick={() => setPopup(true)}
                    />
                  </div>
                </li> */}
                <div
                  className="shape_1 moving_effect"
                  data-direction="y"
                  data-reverse="yes"
                />
                <div
                  className="shape_2 moving_effect"
                  data-direction="y"
                  data-reverse="yes"
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default MajorFeatures;
