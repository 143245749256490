import { google_auth, apple_auth, apple_provider } from 'firebase_config';
import { GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import { useState } from 'react';
import GoogleSignInBtn from 'components/button/google_signin_btn';
import AppleSignInBtn from 'components/button/apple_signin_btn';
import EmailSignInBtn from 'components/button/email_signin_btn';
import { Divider,Box } from '@mui/material';
import { firebaseErrorMsg, getUserProfile } from 'utils/firebase_util';
import { useAuthRepository } from "repository/RootProvider";


function SignUpSocial({
  moveToEmailSignUp,
  onSubmit, 
  setUserDetail
}) {
  const authRepository = useAuthRepository();
  const [errorMsg, setErrorMsg] = useState(null);

  /**
   * 구글 로그인
   */
  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider(); // provider를 구글로 설정
    try {
      setErrorMsg(null);
      let result = await signInWithPopup(google_auth, provider); // popup을 이용한 signup

      let user = await getUserProfile(result.user); 
      setUserDetail(user);
      
      // authRepository.setLastLoginUserInfo(user);
      if(onSubmit) onSubmit();
    }
    catch (error) {
      setErrorMsg(firebaseErrorMsg(error));
    }
  }

  /**
   * 애플 로그인 
   */
  const handleAppleSignUp = async () => {
    try {
      setErrorMsg(null);
      let result = await signInWithPopup(apple_auth, apple_provider);
      let user = await getUserProfile(result.user); 
      setUserDetail(user);

      // authRepository.setLastLoginUserInfo(user);
      if(onSubmit) onSubmit();
    }
    catch (error) {
      setErrorMsg(firebaseErrorMsg(error));
    }
  }

  

  return (
    <Box sx={{
      width:'80%',
      height:'100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}>
      간편 회원 가입 
      <GoogleSignInBtn onClick={handleGoogleSignUp}/>
      <AppleSignInBtn onClick={handleAppleSignUp}/>
      {(errorMsg) && <p style={{color:'red'}}>{errorMsg}</p>}
      <Divider variant="middle"  sx={{width:'100%'}}/>
      또는
      <EmailSignInBtn onClick={()=>{
        moveToEmailSignUp();
      }}/>
    </Box>
  );
}

export default SignUpSocial;