const CommonConst = {    
    BOARD_ID_NOTICE : "0000",                                 // 공지사항 대분류
    
    SUB_BOARD_ID_NOTICE_EVENT : "0000",                       // 공지사항 소분류 - 이벤트
    SUB_BOARD_ID_NOTICE_UPDATE : "0001",                      // 공지사항 소분류 - 업데이트(서버작업 공지 )
    SUB_BOARD_ID_NOTICE_BUG_FIX : "0002",                     // 공지사항 소분류 - 버그수정(버그 Fix 공지 )
    SUB_BOARD_ID_NOTICE_ETC : "0003",                         // 공지사항 소분류 - 기타게시판

    BOARD_ID_QNA : "0001",                                    // QNA 대분류
    SUB_BOARD_ID_QNA : "0000",                                // QNA 소분류
    DATE_TIME_FORMAT : "YYYY-MM-DD HH:mm:ss",
}

export default CommonConst;