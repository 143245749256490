
import React, { useEffect, useState, Fragment } from "react";
import { stickyNav } from "utils/utils";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useAuthRepository } from "repository/RootProvider";
import { observer } from "mobx-react-lite";
import EzHashLink from "components/ez_hash_link";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

const Header = observer(() => {
  const navigate = useNavigate();
  const authRepository = useAuthRepository();
  const location = useLocation();
  const { hash, pathname } = location;

  const [anchorEl, setAnchorEl] = useState(null);
  const [customerEl, setCustomerEl] = useState(null);

  const openCustomer = Boolean(customerEl);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCustomerEl(null);
  };

  const handleLogout = () => {
    try {
      authRepository.doLogout();
      navigate('/');
      handleClose();
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", stickyNav);
  }, []);

  return (
    <div className="devman_tm_header">
      <div className="container">
        <div className="header_inner">
          <div className="logo">
            <Link to="/">
              <img src="img/logo/logo_374_116.png" alt="" />
            </Link>
          </div>
          <div className="menu">
            <Fragment>
              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <EzHashLink to="/#home" label="Home" current_path={pathname + hash} />
                <EzHashLink to="/#maror_features" label="기능소개" current_path={pathname + hash} />
                <EzHashLink to="/#price" label="요금안내" current_path={pathname + hash} />
                <MenuItem onMouseOver={(event)=>{setCustomerEl(event.currentTarget)}}>고객센터</MenuItem>
                {
                  (authRepository.user != null)
                    ?
                    <div onMouseOver={handleClick}>
                      <Avatar src={`data:image/png;base64, ${authRepository.user?.avatar}`}
                       sx={{ width: 40, height: 40, marginLeft:'30px' }} />
                    </div>
                    : <EzHashLink to="/login" label="로그인" current_path={pathname + hash} />
                }
              </Box>

              <Menu
                id="basic-menu"
                anchorEl={customerEl}
                open={openCustomer}
                onClose={() => { setCustomerEl(null) }}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <div  onClick={handleClose}> 
                  <EzHashLink to="/customer/notice" label="공지사항" current_path={pathname + hash} />
                </div>
                <div  onClick={handleClose}> 
                  <EzHashLink to="/customer/notice" label="자주하는질문" current_path={pathname + hash} />
                </div>
                <div  onClick={handleClose}> 
                  <EzHashLink to="/customer/qna" label="나의질문과답변" current_path={pathname + hash} onClick={() => { setCustomerEl(null) }} />
                </div>
              </Menu>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1, },
                      '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        // top: 0, right: 14, width: 10, height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                      ml:1,
                    },
                  }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <Box  onClick={handleClose}  
                  sx={{
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',  
                    margin:'10px',
                    width:'150px'
                  }} > 
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  <HashLink to='/profile'>설정</HashLink>
                </Box>
                <Box  onClick={handleLogout}  
                  sx={{
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',  
                    margin:'10px',
                    width:'150px'
                  }} > 
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  로그아웃
                </Box>
              </Menu>
            </Fragment>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Header;