


export const doGetJson = async (url, method, reqParam ) => {
    try {
        let queryParam = "";
        let lst = [];

        reqParam.forEach( (value, key) => {
            lst.push(key + "=" + encodeURIComponent(value));
        });
        queryParam = lst.join("&");
        url = url + "?" + queryParam;   
        console.log("--------------------------");        
        console.log(url);
        console.log("Query Param : ", queryParam);  
        console.log("--------------------------");

        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('Authorization'),
            },
            credentials: 'include',         // added for cors cookie.
        });

        return await returnResult(response);
    }
    catch(error) {
        throw error;
    }
}


export const doPostJson = async (url , method,  reqParam ) => {
    try {
        console.log("--------------------------");        
        console.log(url);
        console.log("REQUEST PARAM : ", reqParam);
        console.log("--------------------------");
        const requestOptions = {
            method: method,
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('Authorization'),
            },
            credentials: 'include',         // added for cors cookie.
            body: JSON.stringify( Object.fromEntries(reqParam) ) //실제 데이터 파싱하여 body에 저장
        };
        const response = await fetch(url, requestOptions);   
        return await returnResult(response);
    }
    catch(e) {
        throw e;
    }
}


const returnResult = async (response) => {
    try {
        // console.log("--------------------------");
        // console.log("RESPONSE " , response);
        // console.log("--------------------------");

        if(response.ok) {
            const result = await response.json();
            console.log("--------------------------");
            console.log("Response Body : ", result);
            console.log("--------------------------");

            if(result?.status != "success") {
                alert(result?.message);
                return {};
            }
            return result.data;
        }
        // const navigate = useNavigate();
        // // 에러 객체를 생성 합니다. 
        const status = response.status;
        switch(status) {
            case 401 : 
                throw("유효하지 않은 토큰입니다. ");
            case 403 : 
                throw("접속이 종료 되었습니다. ");
            default : 
                alert(response?.statusText);
                break;
        }
        
    }
    catch(error) {
        throw error;
    }
}
