import CountUp from "react-countup";
import { InView } from "react-intersection-observer";
const Counter = ({ end, decimals }) => {
  return (
      <CountUp
        end={end ? end : 100}
        duration={3}
        decimals={decimals ? decimals : 0}
      >
        {({ countUpRef, start }) => (
          <InView as="div" onChange={(inView, entry) => {
            if(inView) {
              start();
            }
          }}>
          <span
              className="tonni_tm_counter"
              data-from="0"
              data-to={end}
              ref={countUpRef}>
              count
            </span>
          </InView>
        )}
      </CountUp>
  );
};

export default Counter;
