import { useState } from 'react';
import {
    Button,
    Box,
    Badge
} from "@mui/material";
import { google_auth } from 'firebase_config';
import { deleteUser } from 'firebase/auth';
import { useAuthRepository } from "repository/RootProvider";
import Avatar from '@mui/material/Avatar';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import { grey } from '@mui/material/colors';
import { URLConst } from 'constant/URLConst';
import { doGetJson } from 'utils/HttpUtils';
import { observer } from "mobx-react-lite";
import { useNavigate } from 'react-router-dom';

const SignOut = observer(() => {
    const authRepository = useAuthRepository();
    const [errorMsg, setErrorMsg] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            let user = new Map();
            user.set("companyId", authRepository.user.companyId);

            let result = await doGetJson(
                URLConst.PANDA_USER_URL,
                'DELETE',
                user
            );

            console.log("FireBase user : ", google_auth.currentUser);
            // Firebase 사용자 삭제
            await deleteUser(google_auth.currentUser);
            // BaroPanda 사용자 삭제
            await authRepository.doLogout();

            navigate('/profile/sign_out_end');
        }
        catch (error) {
            setErrorMsg(error);
        }
    }

    return (
        <div className="devman_tm_member">
            <div className="container">
                <div className="devman_tm_main_title" data-text-align="center">
                    <h3>회원탈퇴</h3>
                    <p>바로판다 회원 탈퇴 요청 페이지 입니다</p>
                </div>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    border: '1px solid #ccc',
                    // bgcolor: '#ccc', 
                    height: '40vh',
                    marginTop: '30px', borderRadius: '10px',
                    padding: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <h5>회원 탈퇴시 모든 정보가 삭제 됩니다.</h5>

                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <PhotoCameraOutlinedIcon sx={{ color: grey[500] }} />
                        }
                    >
                        <Avatar src={`data:image/png;base64, ${authRepository.user?.avatar || ''}`}
                            sx={{ width: 80, height: 80 }}
                        />
                    </Badge>

                    <p>사용자 아이디 : {authRepository.user?.companyId}</p>
                    <p>의 모든 정보 삭제 하시겠습니까?</p>


                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '350px',
                        justifyContent: 'flex-end',
                        marginTop: '50px',
                        marginBottom: '50px',
                    }}>
                        <Button color="primary" variant="contained" onClick={handleSubmit} type="submit">
                            회원탈퇴
                        </Button>
                    </Box>
                </Box>
            </div>
        </div>
    );
});

export default SignOut;