import { useState } from 'react';
import { google_auth} from 'firebase_config';
import { createUserWithEmailAndPassword } from 'firebase/auth';

import { useFormik} from 'formik';
import {
    Button,
    Box,
    TextField
} from "@mui/material";

import * as yup from "yup";

import { firebaseErrorMsg, getUserProfile } from 'utils/firebase_util';
import { useAuthRepository } from "repository/RootProvider";

const validationSchema = yup.object({
    email: yup
        .string('아이디로 사용할 이메일을 입력하세요')
        .email('올바른 이메일 형식이 아닙니다')
        .required('아이디로 사용할 이메일을 입력하세요'),
    password: yup
        .string('비밀번호를 입력하세요')
        .min(8, '8글자 이상 비밀번호를 입력하세요')
        .max(15, '15글자 이하 비밀번호를 입력하세요')
        .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()])[a-zA-Z0-9!@#$%^&*()]{8,16}$/,
            "비밀번호는 영문 숫자, 특수문자를 포함하여야 합니다.")
        .required('비밀번호를 입력하세요'),
});


const SignUpEmail = ({ onCancel, onSubmit , setUserDetail}) => {
    const authRepository = useAuthRepository();
    const [errorMsg, setErrorMsg] = useState(null);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleEmailSignUp(values);
        },
    });
    
    const handleEmailSignUp = async (values) => {  
        try {
            setErrorMsg(null);
            let result = await createUserWithEmailAndPassword(
                google_auth, values.email, values.password);
            let user = await getUserProfile(result.user); 
            setUserDetail(user);

            // authRepository.setLastLoginUserInfo(user);
            if(onSubmit) onSubmit();
        }
        catch (error) {
            setErrorMsg(firebaseErrorMsg(error));
        }
    }

    return (
        <Box sx={{
            width: '80%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}>
            <h4>이메일로 회원가입</h4>

            아이디로 사용할 이메일을 입력하세요

            <TextField
                name="email"
                sx={{ width: '350px' }}
                label="이메일주소"
                variant="outlined"
                margin="normal"
                size="normal"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
                name="password"
                sx={{ width: '350px' }}
                type="password"
                label="비밀번호" variant="outlined"
                margin="normal"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
            />

            {(errorMsg) && <p style={{color:'red'}}>{errorMsg}</p>}

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '350px',
                justifyContent: 'space-between',
                marginTop: '20px',
                marginBottom: '50px',
            }}>
                <Button color="primary" variant="contained" onClick={() => onCancel("2")}>
                    뒤로
                </Button>
                <Button color="primary" variant="contained" onClick={formik.handleSubmit} type="submit">
                    회원가입
                </Button>
            </Box>
        </Box>
    );
}

export default SignUpEmail;