import { useEffect, useState } from "react";
import { HashLink } from 'react-router-hash-link';

import MenuItem from '@mui/material/MenuItem';

const EzHashLink = ({to, label, current_path}) => {
    const [path, setPath] = useState("");
    useEffect(() => {
      if(current_path != "") {
        setPath(current_path);
      }
    }, [current_path]); 
    
    return (
      // <li className= {(to == path) ?  "current" : ""}>
      <MenuItem>
          <HashLink to ={to}>{label}</HashLink>
      </MenuItem>
      // </li>
    )
  }


  export default EzHashLink;