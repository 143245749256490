import { lineBreak } from "utils/utils";
import Counter from "./Counter";
import { Box } from "@mui/material";

const counterData = [
  { smallVlaue: false, value: 95, name: "시장분석건수" },
  { smallVlaue: true, value: 40, name: "가입고객수" },
  { smallVlaue: false, value: 103, name: "마켓연동상품" },
];
const CounterSection = () => {
  return (
    <div className="devman_tm_section">
      <div className="devman_tm_counter_section">
        <div className="container">
          <div className="counter_list">
            <ul>
              {counterData.map((counter, i) => (
                <li key={i}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      color: 'var(--main-color)',
                      fontSize: '72px',
                      alignItems: 'center',
                      // justifyContent: 'space-between', 
                      marginRight: '50px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <Counter end={counter.value} />
                      {counter.smallVlaue ? (
                        <label className="small">+</label>
                      ) : (
                        <label className="big">M</label>
                      )}
                    </Box>
                    <Box sx={{
                      display: 'flex',
                      height: '100%',
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                      marginLeft: '10px',
                      justifyContent:'flex-end'
                    }}>
                      <h4
                        dangerouslySetInnerHTML={lineBreak(counter.name)}
                      ></h4>
                    </Box>
                  </Box>      
                  {/* <div className="list_inner">
                    <div className="wrapper">
                      <div className="value">
                        <span className="abs">
                          <Counter end={counter.value} />
                          {counter.smallVlaue ? (
                            <label className="small">+</label>
                          ) : (
                            <label className="big">M</label>
                          )}
                        </span>
                      </div>
                      <div className="text">
                        <h3
                          dangerouslySetInnerHTML={lineBreak(counter.name)}
                        ></h3>
                      </div>
                    </div>
                  </div> */}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className="shape moving_effect"
          data-direction="y"
          data-reverse="yes"
        />
      </div>
    </div>
  );
};
export default CounterSection;
