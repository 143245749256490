import { ReactComponent as GoogleLogin } from "assets/svg/google/neutral/web_neutral_sq_na.svg";
import { makeStyles } from '@mui/styles';
import { Button } from "@mui/material";

const useStyles = makeStyles({
    root: {
        paddingLeft: 56,
        paddingRight: 56
    },
    rootAbsolute: {
        background: '#fff',
        height: 50,
        width : 250,
        // boxShadow:3,
        textTransform: "none", 
        paddingLeft: 66,
        paddingRight: 50,
        position: "relative",

        '&:hover': {
            backgroundColor: '#fff',
            color: '#3c52b2',
        },
        
        "& .MuiButton-sizeSmall": {
            paddingLeft: 48,
            paddingRight: 32,
            position: "absolute",
            left: 16
        },
        "& .MuiButton-startIcon": {
            position: "absolute",
            left: 16,
            // width: 38,
            // height: 35,
        }
    },
    rootNegativeMargin: {
        paddingLeft: 56,
        paddingRight: 56,
        "& .MuiButton-startIcon": {
            marginLeft: -46,
            marginRight: 36
        }
    }
});

const GoogleSignInBtn = ({
    onClick
}) => {
    const classes = useStyles();
    return( 
        <Button
            onClick={onClick}
            // component="label"
            // role={undefined}
            variant="outlined"
            startIcon={<GoogleLogin/>}
            className={classes.rootAbsolute}>
            Sign in with Google
        </Button>
    )
}


export default GoogleSignInBtn;