import Divider from '@mui/material/Divider';
import EzCheckBox from 'components/ez_check_box';
import { Formik, Form } from 'formik';
import {
  Button,
  Box,
  FormControlLabel
} from "@mui/material";

import Checkbox from '@mui/material/Checkbox';
import * as yup from "yup";

const validationSchema = yup.object().shape({
  ageChecked: yup
    .boolean()
    .required("만 14세 이상 이어야 서비스를 이용하실 수 있습니다. ")
    .oneOf([true], "만 14세 이상 이어야 서비스를 이용하실 수 있습니다."),

  privacyChecked: yup
    .boolean()
    .required("개인정보처리방침을 동의해 주세요")
    .oneOf([true], "개인정보처리방침을 동의해 주세요"),

  policyChecked: yup
    .boolean()
    .required("서비스 이용약관에 동의해 주세요")
    .oneOf([true], "서비스 이용약관에 동의해 주세요")
});

const PolicyAccept = ({ onSubmit, setPolicyState }) => {
  return (
    <Formik
      initialValues={{
        allChecked: false,
        ageChecked: false,
        privacyChecked: false,
        policyChecked: false,
        marketingChecked: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setPolicyState(
          values
        );

        if (onSubmit) onSubmit();
        
      }}
    >
      {({
        values,
        handleChange,
        setValues,
        handleSubmit,
      }) => (
        <Box sx={{ width: '80%', height: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setValues({
                    "ageChecked": e.target.checked,
                    "privacyChecked": e.target.checked,
                    "policyChecked": e.target.checked,
                    "marketingChecked": e.target.checked,
                    "allChecked": e.target.checked
                  }, true);
                }}
                name="allChecked"
                checked={values.allChecked}
              />
            }
            label="모두 동의하기"
          />
          <Divider />
          <EzCheckBox label={"만 14세 이상입니다 (필수)"} name={"ageChecked"} checked={values.ageChecked} onChange={handleChange} />
          <EzCheckBox label={"개인정보처리방침 (필수)"} name={"privacyChecked"} checked={values.privacyChecked} onChange={handleChange} />
          <EzCheckBox label={"서비스 이용 약관 (필수)"} name={"policyChecked"} checked={values.policyChecked} onChange={handleChange} />
          <EzCheckBox label={"마케팅 정보 수신 동의 (선택)"} name={"marketingChecked"} checked={values.marketingChecked} onChange={handleChange} />

          <Divider />
          <Box sx={{
            display: 'flex',
            // alignItems: 'flex-end',
            justifyContent: 'flex-end',
          }}>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              확인            </Button>
          </Box>

        </Box>
      )}
    </Formik>
  );
}

export default PolicyAccept;