
import Main from "Main";
import React from "react";
import { Route, Outlet, Routes, useLocation, Navigate } from 'react-router-dom';
import Header from "components/Header";
import MobileHeader from "components/MobileHeader";
import Customer from "pages/customer/page"
import SignUpMain from "pages/signup/signup_main"
import LoginMain from "pages/login/login_main";
import Profile from "pages/profile/profile";
import SignOut from "pages/profile/sign_out/sign_out";
import SignOutEnd from "pages/profile/sign_out/sign_out_end";
import NoticeBoard from "pages/customer/notice_board";
import QnaBoard from "pages/customer/qna/qna_board";
import QnaWrite from "pages/customer/qna/qna_write";
import QnaEdit from "pages/customer/qna/qna_edit";
import QnaView from "pages/customer/qna/qna_view";
import Copyright from "components/Copyright";
import NoMatch from "pages/notfound/page";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuthRepository } from "repository/RootProvider";

const Layout = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>판매를 쉽게! 찍고 바로판다</title>
        </Helmet>
        <Header />
        <MobileHeader />
        <Outlet />
        <Copyright />
      </div>
    </HelmetProvider>
  );
}

const App = () => {
  return (
    
      <div className="devman_tm_all_wrap" data-magic-cursor="show">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Main />} />
            <Route path="customer" element={<Customer />} />
            <Route path="customer/qna" element={
              <RequireAuth>
                <QnaBoard />
              </RequireAuth>
            } />
            <Route path="customer/qna/write" element={
              <RequireAuth>
                <QnaWrite />
              </RequireAuth>
            } />
            <Route path="customer/qna/edit/:idx" element={
              <RequireAuth>
                <QnaEdit />
              </RequireAuth>
            } />
            <Route path="customer/qna/:idx" element={
              <RequireAuth>
                <QnaView />
              </RequireAuth>
            } />
            <Route path="customer/notice" element={<NoticeBoard />} />
            
            <Route path="login" element={
              <LoginMain />
            } />
            <Route path="signup" element={<SignUpMain />} />
            <Route path="profile" element={
              <RequireAuth>
                <Profile/>
              </RequireAuth>
            } />
            <Route path="profile/sign_out" element={
              <RequireAuth>
                <SignOut/>
              </RequireAuth>
            } />
            <Route path="profile/sign_out_end" element={ <SignOutEnd/> } />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </div>
    
  );
}

function RequireAuth({ children }) {
  // let auth = useAuth();
  const auth = useAuthRepository();
  let location = useLocation();

  if (auth.user === null) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default App;