import React, {  useState } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useBoardRepository } from 'repository/RootProvider';
import QnaForm from './qna_form';
import { useNavigate } from 'react-router-dom';

const QnaWrite = observer(() => {
    const navigate = useNavigate();
    const boardRepository = useBoardRepository();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (values, attachFiles) => {
        
        try {
            setIsLoading(true);
            let result = await boardRepository.insert({
                title: values.title,
                contents: values.contents,
                fileList: attachFiles
            });
            // if(result != null && result.status === 200){
            alert('질문이 등록되었습니다.');
            navigate(-1);
            // }
        }
        catch (e) {
            console.log(e);
        }
        setIsLoading(false);
    }
    
    return (
        <div className="devman_tm_member">
            <div className="container">
                <div className="devman_tm_main_title" data-text-align="center">
                    <h3>나의 질문과 답변</h3>
                    <p>궁금하신 사항을 언제든 남겨주세요. 바로판다 관리자가 24시간 이내에 답변해드리겠습니다.</p>
                </div>
                <Box sx={{ height: 500, width: '100%', marginTop: '50px' }}>
                    <QnaForm isLoading={isLoading} onSubmit={onSubmit} />
                </Box>
            </div>
        </div>

    );
});

export default QnaWrite;