import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Divider } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { MuiFileInput } from 'mui-file-input'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Done, Delete } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { fileToBase64 } from 'utils/file_util';
import moment from 'moment';


const QnaForm = ({
    isLoading = false,
    onSubmit = (values, attachFiles) => { },
    board = {},
}) => {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState([{}]);
    const [attachments, setAttachments] = useState([]);
    const [isMax, setIsMax] = useState(false);
    const maxFileCount = 3;

    useEffect(() => {
        if(board == null ) return;
        if( board.attachFiles != null) 
            setAttachments(board.attachFiles);
    }, [board]);

    // 파일 첨부 최대 갯수를 체크 합니다.
    useEffect(() => {
        const fileCnt = inputs.length + attachments.length;
        setIsMax((fileCnt >= maxFileCount) ? true : false);
    }, [inputs, attachments]);


    /**
     * 파일 첨부 이벤트를 처리 합니다.
     * @param {*} value 
     * @param {*} index 
     * @returns 
     */
    const handleChange = (value, index) => {
        // 파일 필드 삭제 이벤트를 처리 합니다. 
        if (value == null) {
            handleDeleteInput(index);
            return;
        }
        // 선택된 파일 정보를 기존 배열에 적용 합니다. 
        if (index < inputs.length) inputs[index] = value;

        // 새로운 파일 입력 필드를 추가합니다.
        let newInputs = [...inputs];
        if (!isMax && index === inputs.length - 1) {
            newInputs = [...inputs, {}];
        }
        setInputs(newInputs);
    };

    /**
     * 파일 선택 필드 삭제 이벤트를 처리 합니다.
     * @param {*} index 
     * @returns 
     */
    const handleDeleteInput = (index) => {
        // 1개 이하일때는 삭제 불가
        if (inputs.length <= 1) return
        let newArray = [...inputs];
        newArray.splice(index, 1);
        // 빈칸이 없는 경우 추가 합니다. 
        let isNewFieldExist = false;
        newArray.forEach((item) => {
            if (item.name === undefined) isNewFieldExist = true;
        })
        if (!isNewFieldExist) newArray.push({});
        setInputs(newArray);
    };

    const deleteAttach = (attachNo) => {
        let newAttachments = attachments.filter((item) => item.attachNo !== attachNo);
        setAttachments(newAttachments);
    }

    /**
     * 첨부 파일 용량을 체크 합니다.
     * @param {*} item 
     * @returns 
     */
    const isFileLimit = (item) => {
        if (item.name === undefined) return false;
        if (item.size > 5 * 1024 * 1024) return true;
        return false
    }

    /**
     * 파일 목록을 Base64로 변환 합니다.
     * @param {*} inputFiles 
     * @returns 
     */
    const getFileList = async () => {
        const attachFiles = [];
        for (let i = 0; i < inputs.length; i++) {
            const item = inputs[i];
            if (item.name !== undefined) {
                let fileItem = {};
                fileItem.fileName = item.name;
                fileItem.fileSize = item.size;
                fileItem.fileType = item.type;
                fileItem.attachBase64String = await fileToBase64(item);
                attachFiles.push(fileItem);
            }
        }

        return attachFiles;
    }

    const formik = useFormik({
        initialValues: {
            title: board?.title || '',
            contents: board?.content || '',
        },
        enableReinitialize: true,

        validationSchema: Yup.object({
            title: Yup.string()
                .max(100, '제목은 한글 100자 이내로 입력하세요')
                .required('Required'),
            contents: Yup.string()
                .max(4000, '4000자 이내로 입력하세요')
                .required('Required'),
        }),
        onSubmit: async (values) => {
            // 파일 사이즈를 체크 합니다.
            let isOverSize = false;
            inputs.forEach((item) => {
                if (isFileLimit(item)) {
                    isOverSize = true;
                }
            });
            if (isOverSize) {
                alert("파일 사이즈가 5M를 초과합니다.");
                return;
            }

            // attachFiles의 시간 정보를 pasing 합니다. 
            let newFiles = [...attachments];
            
            const newInputs = await getFileList();
            newInputs.forEach((item) => {
                newFiles.push(item);
            });

            if(onSubmit) onSubmit(values, newFiles);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <Button variant="outlined" onClick={() => navigate(-1)}>뒤로</Button>
                <LoadingButton startIcon={<Done />}
                    loading={isLoading} variant="outlined" onClick={formik.handleSubmit}>저장</LoadingButton>
            </Box>
            <Box
                sx={{
                    // border: '1px solid #ccc',
                    height: '100%',
                    // margin: '20px',
                    // paddingTop: '10px',
                    // paddingLeft: '20px',
                    // paddingRight: '20px',
                }}
            >
                <div>
                    <TextField variant="outlined" label="제목을 입력하세요"
                        id="title"
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        fullWidth sx={{ height: '50px' }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.title}
                    />
                    <TextField variant="outlined" label="내용을 입력하세요"
                        id="contents"
                        multiline
                        error={formik.touched.contents && Boolean(formik.errors.contents)}
                        fullWidth maxRows={8} minRows={4}
                        sx={{
                            marginTop: '10px',
                            marginBottom: '10px',
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.contents}
                    />
                    {formik.touched.contents && formik.errors.contents ? (
                        <div style={{ color: 'red' }}>{formik.errors.contents}</div>
                    ) : null}
                </div>
                {attachments.length > 0 &&
                        <Box>
                            <span>첨부파일</span>
                            <Divider />
                            <Box sx={{ marginTop: '10px' }}>
                                {attachments.map((item, index) => (
                                    <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Box>{item.fileName}</Box>
                                        <Box>
                                            <Button onClick={()=>{deleteAttach(item?.attachNo)}} startIcon={<Delete />}>삭제</Button>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    }
                <Box>
                    <div>첨부파일</div>
                    {inputs.map((item, index) => (
                        <MuiFileInput
                            size="small" variant="outlined"
                            fullWidth
                            error={isFileLimit(item)}
                            placeholder="파일을 업로드하세요 [클릭]"
                            onChange={(e) => handleChange(e, index)}
                            key={index}
                            value={item}
                            InputProps={{
                                inputProps: {
                                    accept: '.txt, .gif, .png, .jpg, .jpeg, .bmp, .doc, .xls, .ppt, .docx, .xlsx, .pptx, .zip, .pdf',
                                },
                                startAdornment: <AttachFileIcon />
                            }}
                            clearIconButtonProps={{
                                title: "Remove",
                                children: <CloseIcon fontSize="small" />
                            }}
                        />

                    ))}
                    
                    <ul style={{ margin: '20px' }}>
                        <li>5M 이하의 파일만 첨부 가능합니다.</li>
                        <li>첨부가능 파일확장자 : txt, gif, png, jpg, jpeg, bmp, doc, xls, ppt, docx, xlsx, pptx, zip, pdf</li>
                    </ul>
                    <div>
                        {isMax && <p style={{ color: 'red' }}>첨부파일은 최대 {maxFileCount}개까지 가능합니다.</p>}
                    </div>
                </Box>
                
            </Box>
        </form>
    );
}

export default QnaForm;