export const downloadFile = async (url) => {
    try {
        let response = await fetch(url);
        let blob = await response.blob();
        return blob;
    }
    catch(error) {
        throw error;
    }   
}

/**
 * file Stream을 base64로 변환 합니다.
 * @param {*} file 
 */
export const fileToBase64  = (file)  => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = () => {
            resolve(reader.result);
        }
        reader.onerror = (error) => {
            reject(error);
        }
    });
}


export const blob2base64 = (blob, mimeType) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrlPrefix = `data:${mimeType};base64,`;
        const base64WithDataUrlPrefix = reader.result;
        const base64 = base64WithDataUrlPrefix.replace(dataUrlPrefix, '');
        resolve(base64);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

export const convertImgToBase64URL = async (url) => {
    try {
        if(url == null) return;
        let blob = await downloadFile(url);
        let mimeType = blob.type;
        let base64 = await blob2base64(blob, mimeType);
        
        return base64;
    }
    catch(error) {
        throw error;
    }
}
