import { Box, Chip } from '@mui/material';

const RegisterStep = ({ step }) => {

  return (
    <div className="register_step">
      <div className="step_title">
        {step == 1 && '약관동의'}
        {step == 2 && ' '}
      </div>
      <Box sx={{
        flexDirection: 'row',
      }}>
        <Chip variant="contained" label="1" 
          color={(step == '1') ? "primary" : "default"} 
          // onClick={()=>onMoveTo("1")}
        />
        <Chip variant="contained" label="2" 
          color={(step == '2' || step == '2-1') ? "primary" : "default"} 
          // onClick={()=>onMoveTo("2")}
        />
        <Chip variant="contained" label="3"
          color={(step == '3') ? "primary" : "default"} 
          // onClick={()=>onMoveTo("3")}
        />
        <Chip variant="contained" label="4"
          color={(step == '4') ? "primary" : "default"} 
          // onClick={()=>onMoveTo("3")}
        />
        
      </Box>
    </div>
  );
}

export default RegisterStep;