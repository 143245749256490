import { makeAutoObservable } from "mobx"
import {URLConst} from 'constant/URLConst';
import { doGetJson, doPostJson } from 'utils/HttpUtils';
import FormConst from 'constant/FormConst';

class AuthRepository {
    user = null;

    constructor() {
        makeAutoObservable(this)
    }

    initState() {
        this.user = null;
        this.getLastLoginUserInfo();
    }

    setUserDetail(user) {
        this.user = user;
    }

    // 바로판다 사용자 정보 Upsert를 요청 합니다. 
    async doUpsertUserInfo(userDetail) {
        try {
            console.log(userDetail)
            let user = new Map();
            user.set("companyId", userDetail.companyId);
            user.set("userDeviceToken", userDetail.deviceToken);
            user.set("userFirebaseId", userDetail.uid);
            user.set("usageAgreeFlag", userDetail.usageAgreeFlag);
            user.set("personalInfoFlag", userDetail.personalInfoFlag);
            user.set("confirmFourteenFlag", userDetail.confirmFourteenFlag);
            user.set("eventMsgAcceptFlag", userDetail.eventMsgAcceptFlag);
            user.set("avatar", userDetail.photoURL);
            user.set("nickName", userDetail.nickName);
            user.set("userOrigin", userDetail.userOrigin);
            
            let result = await doPostJson(
                URLConst.PANDA_USER_URL, 
                'POST', 
                user
            );
        }
        catch(e) {
            throw e;
        }
    }

    setLastLoginUserInfo(user) {
        localStorage.setItem(FormConst.AUTHORIZATION, user.accessToken);
        // 회원가입완료, 로그인 완료시 회사 아이디는 
        // 이메일 주소로 간주 합니다.
        localStorage.setItem(FormConst.COMPANY_ID, user.email);
    }

    // LocalStorage에 등록되어 있는 최종 로그인한 사용자 정보를 가져옵니다.
    async getLastLoginUserInfo() {
        try {
            let companyId = localStorage.getItem(FormConst.COMPANY_ID);
            if(companyId != null)  {
                await this.doRefreshUserInfo(companyId);
            }
        }
        catch(e) {
            // 사용자 정보가 없는 경우
            // 마지막 로그인 정보를 삭제 합니다.
            console.log("--------------------");
            console.log(e);
            console.log("--------------------");
            this.doLogout();
        }
    }

    // 사용자 정보를 가져옵니다.
    async doRefreshUserInfo(company_Id) {
        this.user = {company_id: company_Id};
        try {
            let user = new Map();
            user.set("companyId", company_Id);
            let result = await doGetJson(
                URLConst.PANDA_USER_URL, 
                'GET',
                user
            );
            this.setUserDetail(result);
        }
        catch(e ) {
            throw e;
        }   
    }

    /**
     * Local storage에 저장된 사용자 정보를 삭제 합니다. 
     */
    doLogout = () => {
        localStorage.removeItem(FormConst.AUTHORIZATION);
        localStorage.removeItem(FormConst.COMPANY_ID);

        this.setUserDetail(null);
    }

}

export default AuthRepository;