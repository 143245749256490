import { useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom'; 
import { useLocation } from 'react-router-dom';
import LogInSocial from './login_social';
import LogInEmail from 'pages/login/login_email';
import { useAuthRepository } from "repository/RootProvider";

const LoginMain = ({}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const authRepository = useAuthRepository();
    const [step, setStep] = useState("1");

    const onSuccessLogin = async (user) => {
        try {
            // 사용자의 이메일을 companyId로 사용합니다.
            user.companyId = user.email;
            user.usageAgreeFlag = 'Y';
            user.personalInfoFlag = 'Y';
            user.confirmFourteenFlag = 'Y';
            user.eventMsgAcceptFlag = 'N';
            user.userOrigin = user.providerData[0].providerId;
            
            // 최종 로그인 ID를 LocalStorage에 저장합니다.
            await authRepository.setLastLoginUserInfo(user);

            // 바로 판다 사용자 정보 Upsert를 Repository를 통해 요청합니다.
            await authRepository.doUpsertUserInfo(user);

            await authRepository.doRefreshUserInfo(
                user.email
            );

            let from = location.state?.from?.pathname || "/";
            navigate(from, { replace: true });
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="devman_tm_member">
            <div className="devman_tm_main_title" data-text-align="center">
                <h3>로그인 </h3>
            </div>
            <Box sx={{
                width: '80%',
                height: '40vh',
                display: 'flex',
                border: '1px solid #ccc',
                borderRadius: '10px',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
            }}>
                {(step == "1") &&
                    <LogInSocial 
                        moveToEmailSignIn={() => { setStep("2") }} 
                        onSubmit={ onSuccessLogin }
                    />
                }
                {(step == "2") &&
                    <LogInEmail 
                        onSubmit={ onSuccessLogin }
                        onCancel={() => setStep("1")} 
                    />
                }
            </Box>
        </div>
    );
}

export default LoginMain;