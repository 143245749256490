import { Box } from '@mui/material';

export default function Page() {
  return (
    <div className="devman_tm_member">

      <Box sx={{
        width: '80%',
        height: '40vh',
        display: 'flex',
        border: '1px solid #ccc',
        borderRadius: '10px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
      }}>
        <h3>페이지를 찾을 수 없습니다. </h3>
        
      </Box>
    </div>
  );
}