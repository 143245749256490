import {
    Button,
    Box,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from 'react-router-dom';

const SignOutEnd = observer(() => {
    const navigate = useNavigate();

    return (
        <div className="devman_tm_member">
            <div className="container">
                <div className="devman_tm_main_title" data-text-align="center">
                    <h3>회원탈퇴 완료</h3>
                </div>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    border: '1px solid #ccc',
                    // bgcolor: '#ccc', 
                    height: '40vh',
                    marginTop: '30px', borderRadius: '10px',
                    padding: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <h5>회원 탈퇴시 모든 정보가 삭제 되었습니다</h5>

                    <h5>바로판다 서비스를 이용하여 주셔서 감사합니다</h5>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '350px',
                        justifyContent: 'flex-end',
                        marginTop: '50px',
                        marginBottom: '50px',
                    }}>
                        <Button color="primary" variant="contained" onClick={()=>{navigate('/')}} type="submit">
                            홈으로
                        </Button>
                    </Box>
                </Box>
            </div>
        </div>
    );
});

export default SignOutEnd;