import AuthRepository from "./auth_repository";
import PandaUserRepository from "./panda_user_repository";
import BoardRepository from "./board_repository";

class RootStore {
    constructor() {
        this.authRepository = new AuthRepository();
        this.pandaUserRepository = new PandaUserRepository();
        this.baroRepository = new BoardRepository(this);

        this.initStoreState();
    }
    initStoreState = () => {
        this.authRepository.initState();
        this.pandaUserRepository.initState();
    }
}

export default RootStore;