import React, { useEffect, useState } from "react";
import { Box, Button, DialogContent, Divider } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { observer } from "mobx-react-lite";
import { Edit, Delete } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { saveAs } from 'file-saver';
import moment from "moment";
import { useBoardRepository } from "repository/RootProvider";
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import CommonConst from "constant/CommonConst";

const QnaView = observer(() => {
    const navigate = useNavigate();
    const boardRepository = useBoardRepository();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [companyId, setCompanyId] = useState(null);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [updateAt, setUpdateAt] = useState('');
    const [attachments, setAttachments] = useState([]);
    const { idx } = useParams();

    useEffect(() => {
        if (idx == null) return;
        doGetContent();
    }, [idx]);

    const doDelete = async () => {
        try {
            await boardRepository.delete({ boardNo: idx });
            navigate(-1);
        }
        catch (e) {
            console.error(e);
        }
    }

    const doGetContent = async () => {
        try {
            setIsLoading(true);
            let result = await boardRepository.getContentDetail({ boardNo: idx });
            // 게시물 리스트로 반환되어 0번째 데이터를 가져옵니다.
            let record = result.rows[0];
            setTitle(record.title);
            setContent(record.content);
            setAttachments(record.attachFiles);
            setCompanyId(record.companyId);
            setUpdateAt(record.updateAt);
        }
        catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    const downloadAttach = async (attachNo) => {
        const attach = attachments.find(item => item.attachNo === attachNo);
        if (attach == null) {
            alert('파일을 다운로드 할 수 없습니다.');
            return;
        }
        console.log(attach);
        const byteCharacters = atob(attach.attachBase64String.split(",")[1]);
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i++) {
            byteArrays.push(byteCharacters.charCodeAt(i));
        }
        const byteArray = new Uint8Array(byteArrays);

        const blob = new Blob([byteArray], { type: attach.fileType });
        saveAs(blob, attach.fileName);
    }

    return (
        <div className="devman_tm_member">
            <div className="container">
                <div className="devman_tm_main_title" data-text-align="center">
                    <h3>나의 질문과 답변</h3>
                    <p>궁금하신 사항을 언제든 남겨주세요. 바로판다 관리자가 24시간 이내에 답변해드리겠습니다.</p>
                </div>
                <Box sx={{ height: 500, width: '100%', marginTop: '50px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <Button variant="outlined" onClick={() => navigate(-1)}>목록으로</Button>
                        <Box>
                            <Button variant="outlined" startIcon={<Delete />} onClick={() => setOpen(true)} sx={{ marginRight: '10px' }}>삭제</Button>
                            <Button variant="outlined" startIcon={<Edit />} onClick={() => navigate('/customer/qna/edit/' + idx)}>수정</Button>
                        </Box>

                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',

                        height: '100%',
                        // margin: '20px',

                    }}>
                        {isLoading ?
                            <Box sx={{
                                display: 'flex',
                                border: '1px solid #ccc',
                                flex: 1, alignItems: 'center', justifyContent: 'center'
                            }}>
                                <CircularProgress />
                            </Box>
                            :
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                                border: '1px solid #ccc',
                                padding: '20px',
                            }}>
                                <Box sx={{ height: '30px' }}>{title}</Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box sx={{ marginRight: '10px' }}>작성자 : {companyId}</Box>
                                    <Box>작성일 : {moment(updateAt).format(CommonConst.DATE_TIME_FORMAT)}</Box>
                                </Box>
                                <Divider />
                                <Box
                                    dangerouslySetInnerHTML={{ __html: content?.replace(/(?:\r\n|\r|\n)/g, "<br />") }}
                                    sx={{ height: '400px', marginTop: '10px', overflow: 'auto', }} />

                                {/* <Box
                                    // dangerouslySetInnerHTML={{__html: content?.replace(/(?:\r\n|\r|\n)/g, "<br />")}}
                                    sx={{ height:'400px', marginTop: '10px', overflow: 'auto', }}>
                                    {content}
                                </Box> */}


                                {attachments.length > 0 &&
                                    <Box>
                                        <span>첨부파일</span>
                                        <Divider />
                                        <Box sx={{ marginTop: '10px' }}>
                                            {attachments.map((item, index) => (
                                                <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Box>{item.fileName}</Box>
                                                    <Box>
                                                        <Button onClick={() => { downloadAttach(item?.attachNo) }} startIcon={<AttachFileIcon />}>다운로드</Button>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        }


                    </Box>
                </Box>
            </div>
            <Dialog onClose={() => { setOpen(false) }} open={open}>
                <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '50px', marginRight: '50px', marginTop: '30px', marginBottom: '30px' }}>
                    삭제하시겠습니까?
                    <Box sx={{ marginTop: '20px' }}>
                        <Button onClick={() => { setOpen(false) }}>취소</Button>
                        <Button onClick={() => { doDelete() }}>삭제</Button>
                    </Box>
                </Box>
            </Dialog>
        </div>
    );
});

export default QnaView;